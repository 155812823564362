import http from '../http-common';
import authHeader from './auth-header';

const getAll = () => http.get('/admin/users', { headers: authHeader() });

const getPublicAll = () => http.get('/users');

const get = (id) => http.get(`/user/${id}`);

const getUserList = () => http.get('/user');

const getuselist = (params) =>
  http.get(`/UserDetailList`, {
    params,
    headers: authHeader(),
  });

function create(data) {
  console.log(data);
  return http.post('/users', data);
}


const getbyid = (UserGuid) =>
  http.get(`/UserDetailListById`, {
    params: {
      UserGuid,
    },
  });

const remove = (userGuid) =>
  http.delete(`/DeleteUser?userGuid=${userGuid}`, null, {
    params: { userGuid },
  });


const updateuserStatus = (userId, status) => http.put(`/UpdateUserStatus?Status=${status}&userguid=${userId}`);



const getPaginationWithAddrAndCompany = (params) => http.get('/user/all', { params });

const getGlobalSearch = (params) => http.get('/users/paging', { params });
const getProduct = () => http.get('/product/all');

// ASSR APIS
const changePassword = (data) => http.post('/ChangePassword/ChangePassword', data);

const UserServices = {
  getAll,

  getbyid,
  get,
  create,
  remove,
  getPaginationWithAddrAndCompany,
  getPublicAll,
  getGlobalSearch,
  getProduct,
  // ASSR
  changePassword,
  getuselist,
  updateuserStatus,
};

export default UserServices;
