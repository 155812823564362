import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import AppSettings from '../services/AppSettings';
import SocialSettings from '../services/SocialSettings';

export default function Socialmedia() {
  const initialfacebookData = {
    socialMediaId: '',
    allowText: true,
    textLimit: '',
    allowImage: true,
    imagefileLength: '',
    imageSize: '',
    imageMaxWidth: '',
    imageMaxHigth: '',
    allowVideo: true,
    videofileLength: '',
    videoSize: '',
    videoMaxWidth: '',
    videoMaxHigth: '',
    socialMediaName: '',
  };

  const [currentUser, setCurrentUser] = useState(initialfacebookData);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const socialmedia = (id) => {
    setCustomLoader(true);
    SocialSettings.socialmedia(id)
      // .then((response) => {
      //   setCurrentUser(response.data);
      //   setCustomLoader(false);
      //   console.log(response.data);
      // })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setCurrentUser(response.data[0]); // Set the state to the first object in the array
        } else {
          toast.error('No data found!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setCustomLoader(false);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    socialmedia(id);
  }, [id]);

  // const handleInputChange = (event) => {
  //   const { name, type, checked, value } = event.target;

  //   // Update the currentUser state
  //   setCurrentUser((prev) => ({
  //     ...prev,
  //     [name]: type === 'checkbox' ? checked : value,
  //   }));
  // };
  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    const inputValue = type === 'checkbox' ? checked : value; // Handle boolean values for checkboxes
    setCurrentUser({ ...currentUser, [name]: inputValue });
  };

  const updateSocialmedia = () => {
    setLoading(true);
    SocialSettings.updatesocialmedia(currentUser)

      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success('Success: Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Facebook | ASSR </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom style={{ textTransform: 'capitalize' }}>
            {currentUser.socialMediaName}
          </Typography>
        </Stack>
        <Card style={{ padding: 15 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row">
              <div className="col-lg-12">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Allow Text"
                    id="allowText"
                    name="allowText"
                    checked={currentUser.allowText}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Text Limit"
                  id="text-limit"
                  name="textLimit"
                  value={currentUser.textLimit}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-lg-12">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Allow Image"
                    name="allowImage"
                    checked={currentUser.allowImage}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="File Length"
                  id="imagefileLength"
                  name="imagefileLength"
                  value={currentUser.imagefileLength}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6">
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="select-size-label">Select Size</InputLabel>
                  <Select
                    labelId="select-size-label"
                    id="select-size"
                    name="imageSize"
                    value={currentUser.imageSize}
                    onChange={handleInputChange}
                    autoWidth
                    label="Select Size"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="KB">KB</MenuItem>
                    <MenuItem value="MB">MB</MenuItem>
                    <MenuItem value="GB">GB</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2">
                <TextField
                  fullWidth
                  label="Max Width"
                  id="max-width"
                  name="imageMaxWidth"
                  value={currentUser.imageMaxWidth}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-1">
                <p style={{ marginTop: '20px' }}>Px</p>
              </div>

              <div className="col-lg-2">
                <TextField
                  fullWidth
                  label="Max Height"
                  id="Max-height"
                  name="Max-height"
                  value={currentUser.imageMaxHigth}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-1">
                <p style={{ marginTop: '20px' }}>Px</p>
              </div>
              <div className="col-lg-12">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Allow Video"
                    name="allowVideo"
                    checked={currentUser.allowVideo}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="File Size Length"
                  id="file-size-length"
                  name="videofileLength"
                  value={currentUser.videofileLength}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6">
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="video-size-label">Select Size</InputLabel>
                  <Select
                    labelId="video-size-label"
                    id="video-size"
                    name="videoSize"
                    value={currentUser.videoSize}
                    onChange={handleInputChange}
                    autoWidth
                    label="Select Size"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="KB">KB</MenuItem>
                    <MenuItem value="MB">MB</MenuItem>
                    <MenuItem value="GB">GB</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2">
                <TextField
                  fullWidth
                  label="Max Width"
                  id="max-width"
                  name="videoMaxWidth"
                  value={currentUser.videoMaxWidth}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-1">
                <p style={{ marginTop: '20px' }}>Px</p>
              </div>

              <div className="col-lg-2">
                <TextField
                  fullWidth
                  label="Max Height"
                  id="max-height"
                  name="videoMaxHigth"
                  value={currentUser.videoMaxHigth}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-1">
                <p style={{ marginTop: '20px' }}>Px</p>
              </div>

              <div style={{ padding: 15, margin: 5 }}>
                <Button variant="contained" onClick={updateSocialmedia} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
              </div>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
