import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { CiUnlock } from 'react-icons/ci';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // State htmlFor "Remember me" checkbox

  const sendUserData = {
    "username": email,
    "password": password,
  };

  // useEffect(() => {
  //   console.log("Effect triggered");
  //   // Check if 'rememberMe' is set in localStorage
  //   const storedRememberMe = localStorage.getItem('rememberMe');
  //   if (storedRememberMe) {
  //     // Retrieve stored email and password from localStorage
  //     const storedEmail = localStorage.getItem('storedEmail');
  //     const storedPassword = localStorage.getItem('storedPassword');
  //     console.log(storedEmail, storedPassword)
  //     // if (storedEmail && storedPassword) {
  //     setEmail(storedEmail);
  //     setPassword(storedPassword);
  //     setRememberMe(true);
  //     // }
  //   }
  // }, [email, password]); // Update when email or password change

  const handleRememberMeChange = (newValue) => {
    console.log(newValue);
    setRememberMe(newValue);
    if (newValue) {
      // Store email and password in localStorage
      localStorage.setItem('storedEmail', email);
      localStorage.setItem('storedPassword', password);
      localStorage.setItem('rememberMe', 'true');
    } else {
      // Clear stored email and password from localStorage
      localStorage.removeItem('storedEmail');
      localStorage.removeItem('storedPassword');
      localStorage.setItem('rememberMe', 'false');
    }
  };

  // const isValidEmail = (email) => {
  //   // Email validation regular expression
  //   const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   return emailPattern.test(email);
  // };

  const handleClick = async () => {
    console.log('Email', email);

    if (!email || !password) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Don't proceed with the update if fields are empty
    }

    // if (!isValidEmail(email)) {
    //   // Display a toast message indicating that the email format is incorrect
    //   toast.error("Invalid email format", {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return; // Don't proceed with the update if email format is incorrect
    // }

    setLoading(true);
    console.log('User Data', sendUserData);
    try {
      const response = await fetch('https://assr.digitalnoticeboard.biz/api/Authenticate/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendUserData),
      });

      if (!response.ok) {
        // If response status is not ok (invalid credentials)
        toast.error('Invalid Credentials!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      // Successful login
      const result = await response.json();
      console.log('result', result);
      localStorage.setItem('token', result.accessToken);
      localStorage.setItem('id', result.userGUId);

      if (result.accessToken) {
        navigate('/dashboard/app', {
          replace: true,
          state: { successMessage: true }, // Pass the success message as state
        });
      }
    } catch (error) {
      // Handle error here
      console.error('Error:', error);
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false); // Ensure loading state is reset regardless of success or error
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div>
            <div className="card">
              <div className="card-body">
                <div className="text-center mt-4">
                  <div className="mb-0">
                    <Link to="/login" className="">
                      <img src="/assets/images/logo.svg" alt="" height="62" className="auth-logo logo-dark mx-auto" />
                    </Link>
                  </div>
                </div>
                <div className="p-3 mt-0">
                  <h4 className="font-size-18 text-muted mt-2 text-center">Welcome Back !</h4>
                  <p className="text-muted text-center mb-4">Sign in to continue to ASSR</p>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="email">
                      Enter Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter username"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="userpassword">
                      Password
                    </label>
                    <input
                      className="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="mb-2 row mt-4">
                    <div className="col-sm-6">
                      <div className="form-checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input me-1"
                          id="customControlInline"
                          checked={rememberMe}
                          onChange={(e) => handleRememberMeChange(e.target.checked)}
                        />
                        <label className="form-label form-check-label" htmlFor="customControlInline">
                          Remember me
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-6 text-end">
                      <Link to="/forgot-password" className="text-muted" style={{ cursor: 'pointer' }}>
                        <CiUnlock className="mb-1" /> Forgot your password?
                      </Link>
                    </div>
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 text-center">
                      <LoadingButton
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        onClick={handleClick}
                        loading={loading}
                        loadingPosition="center" // Show spinner icon if loading
                      >
                        Login
                      </LoadingButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
