import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import DashboardAppPage from './pages/DashboardAppPage';
import LoginPage from './pages/LoginPage';
import UserPage from './pages/UserPage';
import ProductsPage from './pages/products';
import CompanySettings from './pages/CompanySettings';
import CoinSettings from './pages/CoinSettings';
import NotificationSettings from './pages/NotificationSettings';
import DevSettings from './pages/DevSettings';
import PaymentSettings from './pages/PaymentSettings';
import ApplicationSettings from './pages/ApplicationSettings';
import SMTPSettings from './pages/SMTPSettings';
import PushNotification from './pages/PushNotification';
import Categories from './pages/Categories';
import Countries from './pages/Countries';
import AddCountry from './pages/AddCountry';
import SubscriptionPage from './pages/SubscriptionPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePasswordSettings from './pages/ChangePasswordSettings';
import UserSubscription from './pages/UserSubscription';
import Transaction from './pages/Transaction';
import Usergroup from './pages/Usergroup';
import Hastag from './pages/Hastag';
import Socialmedia from './pages/Socialmedia';
import Snapchat from './pages/Snapchat';
import Imo from './pages/Imo';
import Whatsapp from './pages/Whatsapp';
import Telegram from './pages/Telegram';
import Youtube from './pages/Youtube';
import Linkedin from './pages/Linkedin';
import Instagram from './pages/Instagram';
import Tiktok from './pages/Tiktok';
import Twitter from './pages/Twitter';

// ----------------------------------------------------------------------

export default function Router() {
  const isUserAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token; // Return true if token is present, false otherwise
  };

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" /> },
        {
          path: 'app',
          element: isUserAuthenticated() ? <DashboardAppPage /> : <Navigate to="/login" />,
        },
        // {
        //   path: 'app',
        //   element: <DashboardAppPage />,
        // },
        { path: 'user', element: isUserAuthenticated() ? <UserPage /> : <Navigate to="/login" /> },
        { path: 'subscription', element: isUserAuthenticated() ? <SubscriptionPage /> : <Navigate to="/login" /> },
        { path: 'usersubscription', element: isUserAuthenticated() ? <UserSubscription /> : <Navigate to="/login" /> },
        { path: 'transaction', element: isUserAuthenticated() ? <Transaction /> : <Navigate to="/login" /> },
        // { path: 'user', element: <UserPage /> },
        // { path: 'subscription', element: <SubscriptionPage /> },
        // { path: 'usersubscription', element: <UserSubscription /> },
        // { path: 'transaction', element: <Transaction /> },

        {
          path: 'applicationsettings',
          element: isUserAuthenticated() ? <ApplicationSettings /> : <Navigate to="/login" />,
        },

        // {
        //   path: 'applicationsettings',
        //   element: <ApplicationSettings />,
        // },
        { path: 'devsettings', element: isUserAuthenticated() ? <DevSettings /> : <Navigate to="/login" /> },
        { path: 'companysettings', element: isUserAuthenticated() ? <CompanySettings /> : <Navigate to="/login" /> },
        // { path: 'devsettings', element: <DevSettings /> },
        // { path: 'companysettings', element: <CompanySettings /> },

        {
          path: 'change-password',
          element: isUserAuthenticated() ? <ChangePasswordSettings /> : <Navigate to="/login" />,
        },
        {
          path: 'smtpsettings',
          element: isUserAuthenticated() ? <SMTPSettings /> : <Navigate to="/login" />,
        },
        {
          path: 'usergroup',
          element: isUserAuthenticated() ? <Usergroup /> : <Navigate to="/login" />,
        },
        {
          path: 'notificationssettings',
          element: isUserAuthenticated() ? <NotificationSettings /> : <Navigate to="/login" />,
        },
        {
          path: 'usergroup',
          element: isUserAuthenticated() ? <Usergroup /> : <Navigate to="/login" />,
        },
        {
          path: 'hastag',
          element: isUserAuthenticated() ? <Hastag /> : <Navigate to="/login" />,
        },
        {
          path: 'smplatform/:id',
          element: <Socialmedia />,
        },
        // { path: 'smplatform/likedin/3', element: <Linkedin /> },
        // { path: 'smplatform/instagram/2', element: <Instagram /> },
        // { path: 'smplatform/tiktok//4', element: <Tiktok /> },
        // { path: 'smplatform/whatsapp/5', element: <Whatsapp /> },
        // { path: 'smplatform/telegram/6', element: <Telegram /> },
        // { path: 'smplatform/youtube/7', element: <Youtube /> },
        // { path: 'smplatform/imo/8', element: <Imo /> },
        // { path: 'smplatform/twitter/9', element: <Twitter /> },
        // { path: 'smplatform/snapchat/10', element: <Snapchat /> },

        // { path: 'countries', element: <Countries /> },
        // { path: 'addcountries', element: <AddCountry /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
      index: true,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
      index: true,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
      index: true,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        // { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
