import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Card, Stack, Container, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Autocomplete from '@mui/material/Autocomplete';

import AppSettings from '../services/AppSettings';

export default function NotificationSettings() {
  const initialUserService = {
    id: '',
    fiberBaseId: '',
  };
  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [userGuid, setUserGuid] = useState('');
  const User = [
    { label: 'Anjani' },
    { label: 'Aayush' },
    { label: 'Abhishek' },
    { label: 'Narender' },
    { label: 'Manoj' },
    { label: 'Sagar' },
    { label: 'Ajay' },
  ];

  const sortedUser = User.sort((a, b) => a.label.localeCompare(b.label));

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Capture the selected file
  };

  const getUser = (id) => {
    setCustomLoader(true);
    AppSettings.getNotification(id)
      .then((response) => {
        setCurrentUser(response.data.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // useEffect(() => {
  //   getUser();

  // }, []);
  useEffect(() => {
    const storedUserGuid = localStorage.getItem('id');

    if (storedUserGuid) {
      setUserGuid(storedUserGuid); // Update state with stored userGuid
    }
  }, []); // This effect will run once on component mount

  // This effect will trigger when userGuid changes
  useEffect(() => {
    if (userGuid) {
      console.log('userid', userGuid);
      getUser(userGuid); // Call getUser only after userGuid is set
    }
  }, [userGuid]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true);
    const isEmptyField = Object.values(currentUser).some((value) => value === '');

    if (isEmptyField) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Don't proceed with the update if fields are empty
    }
    AppSettings.updateNotification(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success('Success : Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  const initialDeviceSate = {
    deviceToken: '',
  };

  const [fcmId, setFcm] = useState(initialDeviceSate);

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setFcm({ ...fcmId, [name]: value });
  };

  const noticationSend = () => {
    const isEmptyFields = Object.values(fcmId).some((value) => value === '');

    if (isEmptyFields) {
      toast.error("Error: Device ID field can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if fcmId is blank
    }
    setLoadingSend(true);
    AppSettings.sendTestNotification(fcmId)
      .then((response) => {
        // console.log(JSON.stringify(fcmId, null, 2));
        toast.success('Success : Send Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data.data);
        setLoadingSend(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingSend(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Notification Settings | Carib Bargains </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            FCM Settings
          </Typography>
        </Stack>
        <Card style={{ padding: 15 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Firebase/ Project ID"
                  id="deviceToken"
                  name="deviceToken"
                  value={currentUser.fiberBaseId}
                  onChange={handleInputChanges}
                />
              </div>
              <div className="col-lg-6">
                {/* <TextField
                  fullWidth
                  label="Sender Id / API Key"
                  id="deviceToken"
                  name="deviceToken"
                  value={currentUser.userGuid}
                  onChange={handleInputChanges}
                /> */}
              </div>
              <div className="col-lg-6 mt-2">
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: '16px', fontWeight: '500', marginLeft: '8px', color: '#ccc' }}
                >
                  Config File Upload
                </Typography>
                <input type="file" className="file-int" onChange={handleFileChange} />
              </div>
            </div>

            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={updateUser} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </div>
          </Box>
        </Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={4}>
          <Typography variant="h4" gutterBottom>
            Send Test Notification
          </Typography>
        </Stack>
        <Card style={{ padding: 20 }}>
          <Box component="form" noValidate autoComplete="off">
            <div className="row">
              <div className="col-lg-4 mt-3">
                <Autocomplete
                  disablePortal
                  options={sortedUser}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="User" />}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <TextField
                  fullWidth
                  label="Device Id"
                  id="deviceToken"
                  name="deviceToken"
                  value={fcmId.deviceToken}
                  onChange={handleInputChanges}
                />
              </div>
            </div>

            <div style={{ padding: 15, marginTop: '20px', marginBottom: '20px' }}>
              <Button variant="contained" onClick={noticationSend} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Send Notification'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
