import http from '../http-common';

const socialmedia = (SocialmediaId) =>
  http.get(`GetSocialmediasettingInfo`, {
    params: {
      SocialmediaId,
    },
  });

const updatesocialmedia = (data) => http.put(`UpdateSocialMediaSetting`, data);

const SocialSettings = {
  socialmedia,

  updatesocialmedia,
};

export default SocialSettings;
