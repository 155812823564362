import http from '../http-common';
import authHeader from './auth-header';

const getall = () => http.get('admin/user', { header: authHeader() });

const grouplist = (params) =>
  http.get(`/AdminGroupList`, {
    params,
    headers: authHeader(),
  });

const getbygroupid = (id, page, pageSize) =>
  http.get('/AdminGroupList', {
    params: {
      id,
      page,
      pageSize,
    },
  });

const UsergroupService = {
  getall,
  grouplist,
  getbygroupid,
};

export default UsergroupService;
