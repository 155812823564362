import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { CiUnlock } from 'react-icons/ci';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function ResetPasswordForm({ code }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = {
    "email": email,
  };

  const handleClick = async () => {
    if (!email) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please Enter Your Email', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Don't proceed with the update if fields are empty
    }

    setLoading(true);
    try {
      const response = await fetch('https://assr.digitalnoticeboard.biz/api/ForgotPassword/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(sendEmail),
      });

      if (!response.ok) {
        toast.error('User Not Found!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      // Successful login
      const result = await response.json();
      console.log('result', result);
      localStorage.setItem('token', result.accessToken);

      if (result.accessToken) {
        navigate('/dashboard/app', {
          replace: true,
          state: { successMessage: true }, // Pass the success message as state
        });
      }
    } catch (error) {
      // Handle error here
      console.error('Error:', error);
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false); // Ensure loading state is reset regardless of success or error
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div>
            <div className="card">
              <div className="card-body">
                <div className="text-center mt-4">
                  <div className="mb-0">
                    <Link to="/home" className="">
                      <img src="/assets/images/logo.svg" alt="" height="62" className="auth-logo logo-dark mx-auto" />
                    </Link>
                  </div>
                </div>
                <div className="p-3 mt-0">
                  <h4 className="font-size-18 text-muted mt-2 text-center">Reset Password</h4>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="email">
                      Email Id
                    </label>
                    <input
                      type="text"
                      value={code}
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 text-center">
                      <LoadingButton
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        onClick={handleClick}
                        loading={loading}
                        loadingPosition="center" // Show spinner icon if loading
                      >
                        Send Reset Code
                      </LoadingButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
