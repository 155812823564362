import http from '../http-common';
import authHeader from './auth-header';

const getAll = () => http.get('/admin/users', { headers: authHeader() });

const getPublicAll = () => http.get('/users');

const get = (id) => http.get(`/user/${id}`);


const getPlans = () => {
  console.log('http', http);
  return http.get(`/GetPlans`, { headers: authHeader() });
};

const getaction = (planid) =>
  http.get(`/GetAllSubscriptionPlans`, {
    params: {
      planid,
    },
  });

const create = (data) => {
  console.log(data);
  return http.post('/users', data);
};



const remove = (id) => http.post(`/users/delete/${id}`);


const getPaginationWithAddrAndCompany = (params) => http.get('/user/all', { params });

const getGlobalSearch = (params) => http.get('/users/paging', { params });
const getProduct = () => http.get('/product/all');

// ASSR APIS
const changePassword = (data) => http.post('/ChangePassword/ChangePassword', data);

const SubscriptionService = {
  getAll,
  get,
  create,
  remove,
  getPaginationWithAddrAndCompany,
  getPublicAll,
  getGlobalSearch,
  getProduct,

  changePassword,
  getPlans,
  getaction,
};

export default SubscriptionService;
