import http from '../http-common';
import authHeader from './auth-header';

const getall = () => http.get('admin/user', { header: authHeader() });

const userlist = (params) =>
  http.get(`/UserSubscriptionDetailList`, {
    params,
    headers: authHeader(),
  });

const subscriptionbyid = (Id) =>
  http.get('/UserSubscriptionListbyId', {
    params:{
      Id,
    }
  });

const UserSubscriptionService = {
  userlist,
  subscriptionbyid,
};

export default UserSubscriptionService;
