import http from '../http-common';
// import authHeader from "./auth-header";

// const getAll = () => http.get("/admin/users", { headers: authHeader() });
// const getPublicAll = () => http.get("/users");

const get = (UserGuid) =>
  http.get(`GetSMTP_Info`, {
    params: {
      UserGuid,
    },
  });

// ---anjani---

const getCompany = (UserGuid) => {
  return http.get(`/GetCompanyInfo`, {
    params: {
      UserGuid, // Pass the UserGuid as a query parameter
    },
  });
};

const getApplicationSettings = (UserGuid) => {
  return http.get('/GetApplicationSetting_Info', {
    params: {
      UserGuid, // Pass the UserGuid as a query parameter
    },
  });
};

const getDeveloper = (UserGuid) => {
  return http.get(`/GetDeveloperSetting_Info`, {
    params: {
      UserGuid,
    },
  });
};

// ---anani-end--
const getCoins = () => http.get(`/CoinManagement/getplan`);
const getNotification = (UserGuid) => {
  return http.get(`GetFCMSetting_Info`, {
    params: {
      UserGuid,
    },
  });
};

const updateNotification = (formData) => {
  return http.post(`GetFCMSetting_Info`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// const getDeveloper = () => http.get(`/Developer/get`);
const getPayment = () => http.get(`/Payment/get`);
// const getApplicationSettings = () => http.get(`/Application/get`);

const create = (data) => {
  console.log(data);
  return http.post('/users', data);
};

const update = (data) => http.put(`UpdateSMTP_Info`, data);
const updateCompany = (data) => http.put(`/UpdateCompanyInfo`, data);
const updateCoins = (data) => http.post(`/CoinManagement/update`, data);
// const updateNotification = (data) => http.put(`/Notification/update`, data);
const updateDeveloper = (data) => http.put(`/UpdateDeveloperSetting_Info`, data);
const updatePayment = (data) => http.put(`/Payment/update`, data);
const updateApplicationSettings = (data) => http.put(`/UpdateApplicationSetting_Info`, data);

const remove = (id) => http.post(`/users/delete/${id}`);

const sendTestNotification = (data) => http.post(`/send-notification`, data);

// const sendSMTPNotification = (email) => http.post(`/SMTP_SendinfoForTest/${email}`);
// const sendSMTPNotification = (email, userGuid) => {
//   return http.post(`/SMTP_SendinfoForTest`, {
//     Email: email,
//     UserGuid: userGuid,
//   });
// };

const sendSMTPNotification = (Email, UserGuid) => {
  return http.post(`/SMTP_SendinfoForTest?Email=${encodeURIComponent(Email)}&UserGuid=${UserGuid}`);
};
// const sendSMTPNotification = (email) => {
//   return http
//     .post("/Notification/testnotification", {
//       email,
//     })
//     .then((response) => {
//       console.log(response.data);
//       return response.data;
//     });
// };

const AppSettings = {
  get,
  create,
  update,
  remove,
  getCompany,
  updateCompany,
  getCoins,
  updateCoins,
  getNotification,
  updateNotification,
  sendTestNotification,
  getDeveloper,
  updateDeveloper,
  sendSMTPNotification,
  getPayment,
  updatePayment,
  getApplicationSettings,
  updateApplicationSettings,
};

export default AppSettings;
