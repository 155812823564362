import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import AppSettings from '../services/AppSettings';

export default function SMTPSettings() {
  const initialUserService = {
    id: '',
    email: '',
    // username: '',
    password: '',
    host: '',
    port: '',
    mailToCC: '',
    enable_SSl: false,
    userDefaultCredentials: false,
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [email, setSmtpEmail] = useState('');
  const [userGuid, setUserGuid] = useState('');

  const getUser = (id) => {
    setCustomLoader(true);
    AppSettings.get(id)
      .then((response) => {
        // if (response.data && response.data.length > 0) {
        //   setCurrentUser(response.data[0]);
        // }
        console.log('anjani', response);
        setCurrentUser(response.data);
        setCustomLoader(false);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    const storedUserGuid = localStorage.getItem('id');

    if (storedUserGuid) {
      setUserGuid(storedUserGuid); // Update state with stored userGuid
    }
  }, []); // This effect will run once on component mount

  // This effect will trigger when userGuid changes
  useEffect(() => {
    if (userGuid) {
      console.log('userid', userGuid);
      getUser(userGuid); // Call getUser only after userGuid is set
    }
  }, [userGuid]); // Dep

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCurrentUser({ ...currentUser, [name]: value });
  // };
  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    const inputValue = type === 'checkbox' ? checked : value; // Handle boolean values for checkboxes
    setCurrentUser({ ...currentUser, [name]: inputValue });
  };

  const isValidEmail = (email) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const updateUser = () => {
    const isEmailValid = isValidEmail(currentUser.email);

    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error('Invalid email format', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // Check if any of the fields in currentUser are empty
    const isEmptyField = Object.values(currentUser).some((value) => value === '');

    if (isEmptyField) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Don't proceed with the update if fields are empty
    }
    setLoading(true);
    AppSettings.update(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        setLoading(false);
        toast.success('Success : Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data);
      })
      .catch((e) => {
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        console.log(e);
      });
  };

  const isValidEmailSend = (email) => {
    // Email validation regular expression
    const emailPatternSend = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPatternSend.test(email);
  };

  const noticationSMTPSend = () => {
    setLoadingSend(true);
    const isEmailValidSent = isValidEmailSend(email);

    // Display error messages for invalid fields
    if (!isEmailValidSent) {
      toast.error('Invalid email format', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!email) {
      toast.error("Error: Email field can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if email is blank
    }

    console.log(email);

    AppSettings.sendSMTPNotification(email, userGuid)
      .then((response) => {
        toast.success('Success: Notification Sent', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data);
        setLoadingSend(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingSend(false);
      });
  };

  console.log(loadingSend);

  return (
    <>
      <Helmet>
        <title> SMTP Setting | Carib Bargains </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            SMTP Setting
          </Typography>
        </Stack>
        <Card>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { marginTop: 2, marginBottom: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            {/* <div className='col-lg-6'>
              <TextField
                fullWidth
                label="Id"
                id="id"
                name="id"
                value={currentUser.id}
                onChange={handleInputChange}
                hidden
              />
            </div> */}
            <div className="row" style={{ padding: '20px' }}>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Email Address"
                  id="email"
                  name="email"
                  value={currentUser.email}
                  onChange={handleInputChange}
                />
              </div>

              {/* <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="User Name"
                  id="username"
                  name="username"
                  value={currentUser.username}
                  onChange={handleInputChange}
                />
              </div> */}

              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Password"
                  id="password"
                  name="password"
                  value={currentUser.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Host"
                  id="host"
                  name="host"
                  value={currentUser.host}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Port"
                  id="port"
                  name="port"
                  value={currentUser.port}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Mailtocc"
                  id="email"
                  name="mailToCC"
                  value={currentUser.mailToCC}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Enable SSL"
                  id="enable_SSl"
                  name="enable_SSl"
                  checked={currentUser.enable_SSl}
                  onChange={handleInputChange}
                  sx={{ marginLeft: 1 }}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Use Default Credential"
                  id="userDefaultCredentials"
                  name="userDefaultCredentials"
                  checked={currentUser.userDefaultCredentials}
                  onChange={handleInputChange}
                  sx={{ marginLeft: 1 }}
                />
              </FormGroup>
            </div>
            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} mt={2}>
          <Typography variant="h4" gutterBottom>
            Send Test Notification
          </Typography>
        </Stack>
        <Card>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  label="Email Address"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setSmtpEmail(e.target.value)}
                />
              </div>
            </div>
            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={noticationSMTPSend} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Send Mail'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
