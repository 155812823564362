import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { Box, List, ListItemText } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  // State for toggling submenus
  const [showAppSettingsSubmenu, setShowAppSettingsSubmenu] = useState(false);
  const [showSocialMediaSubmenu, setShowSocialMediaSubmenu] = useState(false);

  const handleAppSettingsClick = () => {
    setShowAppSettingsSubmenu((prev) => !prev);
    setShowSocialMediaSubmenu(false); // Close Social Media submenu if Application Settings is opened
  };

  const handleSocialMediaClick = () => {
    setShowSocialMediaSubmenu((prev) => !prev);
    setShowAppSettingsSubmenu(false); // Close Application Settings submenu if Social Media is opened
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            onAppSettingsClick={handleAppSettingsClick}
            onSocialMediaClick={handleSocialMediaClick}
            showAppSettingsSubmenu={showAppSettingsSubmenu}
            showSocialMediaSubmenu={showSocialMediaSubmenu}
          />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, onAppSettingsClick, onSocialMediaClick, showAppSettingsSubmenu, showSocialMediaSubmenu }) {
  const { title, path, icon, info, submenu, hasSubmenu } = item;

  if (hasSubmenu) {
    // Handling for the specific "Application Settings" and "Social Media Settings"
    const isAppSettings = title === 'Settings';
    const isSocialMedia = title === 'Social Media Settings';

    return (
      <Box>
        <StyledNavItem
          sx={{ color: 'text.primary', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
          onClick={isAppSettings ? onAppSettingsClick : onSocialMediaClick}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          <span>
            {(isAppSettings && showAppSettingsSubmenu) || (isSocialMedia && showSocialMediaSubmenu) ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </span>
        </StyledNavItem>

        <List disablePadding>
          {((isAppSettings && showAppSettingsSubmenu) || (isSocialMedia && showSocialMediaSubmenu)) &&
            submenu.map((submenuItem) => (
              <NavItem
                key={submenuItem.title}
                item={submenuItem}
                onAppSettingsClick={onAppSettingsClick}
                onSocialMediaClick={onSocialMediaClick}
                showAppSettingsSubmenu={showAppSettingsSubmenu}
                showSocialMediaSubmenu={showSocialMediaSubmenu}
              />
            ))}
        </List>
      </Box>
    );
  }

  if (path) {
    return (
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>
    );
  }

  return (
    <StyledNavItem sx={{ color: 'text.primary' }}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}
