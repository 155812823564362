import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../custom/Subscription.css';
import {
  Card,
  Tooltip,
  Stack,
  Button,
  Container,
  Typography,
  Box,
  styled,
  Pagination,
  Avatar,
  Switch,
} from '@mui/material';
import { DataGrid, GridPagination, GridToolbar, GridFooterContainer } from '@mui/x-data-grid';
import Modal from 'react-bootstrap/Modal';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import hastagService from '../services/HastagService';

// import { setDate } from 'date-fns';

export default function Hastag() {
  const initialUserService = {
    id: 'id',
    fullName: '',
    email: '',
    phoneNumber: '',
    photoUrl: '',
    createdOn: '',
    userName: '',
  };
  const [customLoader, setCustomLoader] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showproduct, setShowproduct] = useState(false);
  const handleCloseproduct = () => setShowproduct(false);
  const handleShowproduct = () => setShowproduct(true);
  const [currentUser, setCurrentUser] = useState(initialUserService);

  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [refreshList, setRefreshList] = useState(true);

  const formatDate = (isoString) => {
    return moment(isoString).format('MMMM D, YYYY h:mm:ss A');
  };

  // const { id } = useParams();
  // console.log(id);

  // const getUser = (id) => {
  //   setLoading(true);
  //   SubscriptionService.get(id)
  //     .then((response) => {
  //       setCurrentUser(response.data);
  //       setLoading(false);
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       console.log(e);
  //     });
  // };

  // useEffect(() => {
  //   if (id) getUser(id);
  // }, [id]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = () => {
  //   hastagService
  //     .gethastag()
  //     .then((respons) => {
  //       console.log('gethastag', respons.data);
  //       setData(respons.data.data);
  //     })
  //     .catch((eror) => {
  //       console.log('error gethastag', eror);
  //     });
  // };

  const columns = [
    {
      field: 'userid',
      headerName: 'User ID',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'hashtagName',
      headerName: 'Hastag Name',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },

    {
      field: 'createdOn',
      headerName: 'Created Date',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'hashtagCount',
      headerName: 'No. of Time Used',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'Action',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const onClickView = async (params) => {
          try {
            // Call API to fetch data by id
            const response = await hastagService.getbyhastagid(params);

            // Check if response is successful
            if (response.status === 200) {
              const responseData = response.data;
              console.log('Fetched Data:', responseData);

              // Assuming the response is structured like the example you provided
              if (responseData.data && responseData.data.length > 0) {
                setCurrentUser(responseData.data[0]); // Set the first (and only) object in state
              } else {
                console.log('No data found in response');
              }
            } else {
              console.error('Failed to fetch data:', response.status);
            }
          } catch (error) {
            console.error('Fetch error:', error);
          }
        };

        return (
          <>
            {/* <Button
              onClick={() => {
                onClickView();
                handleShowproduct();
              }}
              variant="contained"
              size="small"
              color="warning"
              style={{ marginLeft: 16 }}
              startIcon={<RemoveRedEyeIcon />}
            >
              View
            </Button> */}

            <Tooltip title="View" arrow>
              <Button
                onClick={() => {
                  onClickView(params.row.id);
                  handleShowproduct();
                }}
                variant="outlined"
                startIcon={<VisibilityIcon />}
                color="warning"
                style={{ border: 'none', borderRadius: 0, minWidth: '40px', padding: '0px' }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [totalItems, setTotalItems] = useState([0]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageSizes = [25, 50, 100];

  const getRequestParams = (page, pageSize) => {
    const params = {};
    if (page) {
      params.pageNumber = page - 1; // Convert to zero-based index
    }
    if (pageSize) {
      params.pageSize = pageSize;
    }
    return params;
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const retrieveTutorials = () => {
    // setLoading(true);
    const params = getRequestParams(page, pageSize);

    hastagService
      .gethastag(params)
      .then((response) => {
        const { totalRecords, pageSize, currentPage, totalPages } = response.data.pagination;
        const { data } = response.data;
        if (data === 0 || data === undefined) {
          setData([]);
          return;
        }
        setData(data);
        setCount(totalPages);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log('error:', e);
      });
  };

  useEffect(retrieveTutorials, [page, pageSize, refreshList]);

  function CustomPagination() {
    return (
      <>
        <Pagination
          className="my-3"
          color="secondary"
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <Box>
          <div className="float-right">
            Showing the first {pageSize * (page - 1) + 1} -{' '}
            {totalItems > pageSize * (page - 1) + pageSize ? pageSize * (page - 1) + pageSize : totalItems} results of{' '}
            {totalItems} rows
          </div>
          <Typography>
            Page: {page} of {count}
          </Typography>
        </Box>
      </>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
        <GridPagination />
      </GridFooterContainer>
    );
  }
  return (
    <>
      <Helmet>
        <title> ASSR </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Hastag
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <Box sx={{ height: 500, width: '100%', padding: '20px' }}>
            <DataGrid
              rows={data}
              columns={columns}
              getRowId={(rows) => rows.userid}
              onSelectionModelChange={setSelectionModel}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={pageSizes}
              // checkboxSelection
              preProcessEditCellProps
              localeText={{
                footerRowSelected: CustomPagination,
              }}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
                Footer: CustomFooter,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </Card>

        <Modal show={showproduct} onHide={handleCloseproduct} size="lg" style={{ marginLeft: '2%' }}>
          <Modal.Header closeButton>
            <Modal.Title>User Id: {currentUser.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ borderBottom: 'var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)' }}
          >
            <Stack direction="column" alignItems="center" spacing={2}>
              {' '}
              {/* Center items vertically */}
              <Avatar
                alt={currentUser.name}
                src={currentUser.photoUrl}
                style={{ width: '120px', height: '120px', margin: 'auto' }}
              />{' '}
              {/* Increase avatar size */}
              <Stack style={{ width: '100%', textAlign: 'center' }}>
                {' '}
                {/* Center the role text */}
                {/* <b>Role:</b> {currentUser.roles.name} */}
              </Stack>
            </Stack>
            <Stack className="bolderHead row mt-3" direction="row" alignItems="center" justifyContent="space-between">
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Full Name:</b> {currentUser.fullName}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Email:</b> {currentUser.email}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Phone Number:</b> {currentUser.phoneNumber}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>User Name:</b> {currentUser.userName}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Created On:</b> {currentUser.createdOn}
                </Stack>
              </div>
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseproduct}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleCloseproduct}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
