import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../custom/Subscription.css';
import { Card, Stack, Button, Container, Typography, Box, styled, Pagination, Avatar, Switch } from '@mui/material';
import { DataGrid, GridPagination, GridToolbar, GridFooterContainer } from '@mui/x-data-grid';
import Modal from 'react-bootstrap/Modal';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useParams } from 'react-router-dom';
// import AppSettings from '../services/AppSettings';
import SubscriptionService from '../services/SubscriptionService';

// import { setDate } from 'date-fns';

export default function SubscriptionPage() {
  const initialUserService = {
    planId: 'id',
    planName: '',
    price: '',
    connectedChannels: '',
    smartContentSuggestionsMonthly: '',
    imageSuggestionsMonthly: '',
    draftedPosts: '',
    dailyPostInspirations: '',
    postsDaily: '',
    scheduledPostsQueue: '',
  };
  const [customLoader, setCustomLoader] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showproduct, setShowproduct] = useState(false);
  const handleCloseproduct = () => setShowproduct(false);
  const handleShowproduct = () => setShowproduct(true);
  const [currentUser, setCurrentUser] = useState(initialUserService);

  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  console.log(id);

  const getSubscriptionplan = () => {
    setLoading(true);
    SubscriptionService.getPlans()
      .then((response) => {
        console.log('getPlans', response);
        setData(response.data);
        setLoading(false);
      })
      .catch((eror) => {
        setLoading(false);
        console.log('error getPlans', eror);
      });
  };

  useEffect(() => {
    getSubscriptionplan();
  }, []);

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'planName',
      headerName: 'Plan Name',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'connectedChannels',
      headerName: 'Connected Channels',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'smartContentSuggestionsMonthly',
      headerName: 'SmartContent Suggestions Monthly',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'imageSuggestionsMonthly',
      headerName: 'Image Suggestions Monthly',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'dailyPostInspirations',
      headerName: 'Daily Post Inspirations',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'draftedPosts',
      headerName: 'Drafted Posts',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'postsDaily',
      headerName: 'Posts Daily',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },

    {
      field: 'scheduledPostsQueue',
      headerName: 'Scheduled Posts Queue',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'Action',
      width: 150,
      sortable: false,
      headerAlign: 'Start',
      align: 'Start',
      renderCell: (params) => {
        const onClickView = async (planid) => {
          try {
            const response = await SubscriptionService.getaction(planid); // Call the getbyid method from UserServices

            if (response.status === 200) {
              const responseData = response.data;
              console.log('Fetched user data:', responseData); // Debug: Check if data is fetched correctly

              if (Array.isArray(responseData) && responseData.length > 0) {
                setCurrentUser(responseData[0]); // Set the first item of the array
              } else {
                console.error('Unexpected response format or empty array');
              }
            } else {
              console.error('Error fetching user details:', response.status);
            }
          } catch (error) {
            console.error('Fetch error:', error);
          }
        };

        return (
          <>
            <Button
              onClick={() => {
                onClickView(params.row.id);
                handleShowproduct();
              }}
              variant="contained"
              size="small"
              color="warning"
              style={{ marginLeft: 16 }}
              startIcon={<RemoveRedEyeIcon />}
            >
              View
            </Button>
          </>
        );
      },
    },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        {/* <CustomPagination /> */}
        <GridPagination />
      </GridFooterContainer>
    );
  }
  return (
    <>
      <Helmet>
        <title> ASSR </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Subscription Plans
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <Box sx={{ height: 400, width: '100%', padding: '20px' }}>
            <DataGrid
              rows={data}
              columns={columns}
              disableColumnMenu
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={10}
              onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
              preProcessEditCellProps
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,

                  quickFilterProps: { debounceMs: 500 },
                },
                row: {
                  alignItems: 'center',
                  textAlign: 'center',
                  display: 'center',
                  justifyContent: 'center',
                },
              }}
              loading={loading}
            />
          </Box>
        </Card>

        <Modal show={showproduct} onHide={handleCloseproduct} size="lg" style={{ marginLeft: '2%' }}>
          <Modal.Header closeButton>
            <Modal.Title className="title-heading">Subscription Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ borderBottom: 'var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)' }}
          >
            <Stack className="bolderHead row mt-3" direction="row" alignItems="center" justifyContent="space-between">
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Plan Name:</b> {currentUser.planName}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Price:</b> {currentUser.price}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Connected Channels:</b> {currentUser.connectedChannels}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>SmartContent Suggestions Monthly:</b> {currentUser.smartContentSuggestionsMonthly}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Image Suggestions Monthly:</b> {currentUser.imageSuggestionsMonthly}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Daily Post Inspirations:</b> {currentUser.dailyPostInspirations}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Drafted Posts:</b> {currentUser.draftedPosts}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Posts Daily:</b> {currentUser.postsDaily}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Scheduled Posts Queue:</b> {currentUser.scheduledPostsQueue}
                </Stack>
              </div>
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outlined" color="error" onClick={handleCloseproduct}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleCloseproduct}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
