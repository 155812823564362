import http from '../http-common';
import authHeader from './auth-header';

const getpayment = (params) =>
  http.get(`/AllPaymentsReceiveList`, {
    params,
    headers: authHeader(),
  });

const getbyidpayment = (id) =>
  http.get(`GetTransectionDetailsbyId`, {
    params: {
      id,
    },
  });

const TransactionService = {
  getpayment,
  getbyidpayment,
};
export default TransactionService;
