import http from '../http-common';
import authHeader from './auth-header';

const getall = () => http.get('admin/user', { header: authHeader() });

const gethastag = (params) =>
  http.get(`/Post/GetAllHashtagDetails`, {
    params,
    headers: authHeader(),
  });

const hastagService = {
  gethastag,
  getall,
};

export default hastagService;
