import { Helmet } from 'react-helmet-async';
import ReactApexChart from 'react-apexcharts';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
// @mui
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { Grid, Card, Container, Typography, List, ListItem, ListItemText, Divider, Box, Alert } from '@mui/material';
// components
import Iconify from '../components/iconify';
import SubscriptionService from '../services/SubscriptionService';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import DashboardServices from '../services/DashboardServices';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const location = useLocation();
  const { state } = location;

  const [countTop, setCountTop] = useState({});
  const [recentUsers, setRecentUsers] = useState([]);
  const [recentPayments, setRecentPayments] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);

  const getTopCount = async () => {
    // setCustomLoader(true);
    DashboardServices.getDashboardList()
      .then((response) => {
        setCountTop(response.data.data);
        // setCustomLoader(false);
        console.log('Dashboard Data', response.data.data);
      })
      .catch((e) => {
        // setCustomLoader(false);
        console.log(e);
      });
  };

  const getTopCountList = async () => {
    // setCustomLoader(true);
    DashboardServices.getListOfTop()
      .then((response) => {
        setRecentUsers(response.data.data.RecentUsers);
        setRecentPayments(response.data.data.RecentPayments);
        setRecentPosts(response.data.data.RecentPosts);
        // setCustomLoader(false);
        console.log('Dashboard List', response.data.data);
      })
      .catch((e) => {
        // setCustomLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getTopCount();
    getTopCountList();
  }, []);

  useEffect(() => {
    if (state && state.successMessage) {
      toast.success('Login Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [state]);

  const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };

  // --Start--socisl media line chart--

  const [chartData, setChartData] = useState({
    series: [
      { name: 'facebook', data: [560, 20, 30, 220, 50, 60, 70, 80, 90, 100, 110, 100] },
      { name: 'Instagram', data: [15, 25, 35, 45, 55, 65, 75, 85, 95, 105, 115, 125] },
      { name: 'Youtube', data: [20, 330, 40, 450, 60, 70, 80, 90, 100, 110, 120, 130] },
      { name: 'Imo', data: [25, 35, 45, 355, 65, 75, 155, 95, 105, 115, 125, 135] },
      { name: 'SnapChat', data: [30, 40, 520, 60, 70, 380, 90, 120, 110, 120, 130, 140] },
      { name: 'Whatsapp', data: [35, 45, 55, 65, 75, 85, 95, 605, 115, 125, 135, 145] },
      { name: 'Pinterest', data: [40, 50, 60, 70, 80, 90, 100, 710, 120, 130, 140, 150] },
      { name: 'Linkedin', data: [453, 55, 65, 75, 85, 95, 105, 115, 125, 135, 745, 155] },
      { name: 'Twitter', data: [50, 60, 70, 80, 90, 100, 1110, 120, 130, 140, 540, 160] },
      { name: 'Tiktok', data: [55, 65, 75, 85, 95, 785, 115, 125, 135, 145, 155, 165] },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
        curve: 'smooth',
      },
      title: {
        text: '',
        align: 'left',
      },
      // legend: {
      //   tooltipHoverFormatter: function (val, opts) {
      //     return `${val} - <strong>${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}</strong>`;
      //   },
      // },
      legend: {
        /* eslint-disable func-names */
        tooltipHoverFormatter(val, opts) {
          return `${val} - <strong>${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}</strong>`;
        },
        /* eslint-enable func-names */
      },
      markers: {
        size: 5,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          '01 Jan',
          '02 Jan',
          '03 Jan',
          '04 Jan',
          '05 Jan',
          '06 Jan',
          '07 Jan',
          '08 Jan',
          '09 Jan',
          '10 Jan',
          '11 Jan',
          '12 Jan',
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter(val) {
                return `${val} (mins)`;
              },
            },
          },
          {
            title: {
              formatter(val) {
                return `${val} per session`;
              },
            },
          },
          {
            title: {
              formatter(val) {
                return `${val}`;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    },
  });
  // End socisl media line chart--------
  // start subscription Chum chart
  const generateData = (count, { min, max }) => {
    return Array.from({ length: count }, () => Math.floor(Math.random() * (max - min + 1)) + min);
  };

  const [series] = useState([
    { name: 'January', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'February', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'March', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'April', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'May', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'June', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'July', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'August', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'September', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'October', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'November', data: generateData(31, { min: -30, max: 55 }) },
    { name: 'December', data: generateData(31, { min: -30, max: 55 }) },
  ]);

  const options = {
    chart: {
      height: 400,
      type: 'heatmap',
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            { from: -30, to: 5, name: 'low', color: '#00A100' },
            { from: 6, to: 20, name: 'medium', color: '#128FD9' },
            { from: 21, to: 45, name: 'high', color: '#FFB200' },
            { from: 46, to: 55, name: 'extreme', color: '#FF0000' },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    title: {
      text: '',
    },
    xaxis: {
      categories: Array.from({ length: 31 }, (_, i) => i + 1), // Display days 1-31 on the x-axis
      tickAmount: 30, // Ensure all 31 days are displayed
      labels: {
        rotate: -45, // Rotate labels to avoid overlapping
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  // end subscription Chum chart

  // Pie chart
  const [seriess] = useState([44, 55, 83, 43, 22, 25, 46, 78, 40, 20]);
  const [optionss] = useState({
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: [
      'facebook',
      'Instagram',
      'Whatsapp',
      'Imo',
      'Linkedin',
      'Tiktok',
      'Snapchat',
      'Twitter',
      'Pinterest',
      'Youtube',
    ],
    responsive: [
      {
        breakpoint: 480,
        optionss: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });
  // end by pie chart
  // Recent payment----

  const [data, setData] = useState([]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = () => {
  //   SubscriptionService.getPlans()
  //     .then((respons) => {
  //       console.log('getPlans', respons);
  //       setData(respons.data);
  //     })
  //     .catch((eror) => {
  //       console.log('error getPlans', eror);
  //     });
  // };

  const columns = [
    {
      field: 'id',
      headerName: 'S.N.',
      width: 50,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'start',
      align: 'start',
    },
    {
      field: 'useremil',
      headerName: 'User Email',
      width: 210,
      HedaerClassname: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'start',
    },
    {
      field: 'Planid',
      headerName: 'Plan ID',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'start',
    },
    {
      field: 'datetime',
      headerName: 'Date Time',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'start',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'start',
    },
  ];

  const rows = [
    { id: 1, useremil: 'john.doe@example.com', Planid: 'P-001', datetime: '2024-08-20 14:30', amount: '$50' },
    { id: 2, useremil: 'jane.smith@example.com', Planid: 'P-002', datetime: '2024-08-19 10:15', amount: '$75' },
    { id: 3, useremil: 'michael.brown@example.com', Planid: 'P-003', datetime: '2024-08-18 09:45', amount: '$100' },
    { id: 4, useremil: 'sarah.jones@example.com', Planid: 'P-004', datetime: '2024-08-17 12:00', amount: '$120' },
    { id: 5, useremil: 'david.wilson@example.com', Planid: 'P-005', datetime: '2024-08-16 16:00', amount: '$150' },
  ];

  return (
    <>
      <Helmet>
        <title> Dashboard | ASSR </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Users"
              // total={countTop?.total_post}
              total={1000}
              color="primary"
              icon={'ant-design:team-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Post"
              // total={countTop?.total_users}
              total={100}
              color="error"
              icon={'ant-design:message-outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Payment"
              // total={countTop?.total_users}
              total={200}
              color="success"
              icon={'ant-design:dollar-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Subscriptions"
              total={234}
              color="warning"
              icon={'ant-design:pay-circle-filled'}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <Alert icon={false} severity="info" variant="filled">
              Recent Users
            </Alert>
            {recentUsers.map((user, index) => (
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem button sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}>
                  <ListItemText
                    primary={user.name}
                    primaryTypographyProps={{
                      color: 'primary',
                      fontWeight: 'medium',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </List>
            ))}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Alert icon={false} severity="info" variant="filled">
              Recent Posts
            </Alert>
            {recentPosts.map((user, index) => (
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem button>
                  <ListItemText
                    primary={user.title}
                    primaryTypographyProps={{
                      color: 'primary',
                      fontWeight: 'medium',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </List>
            ))}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Alert icon={false} severity="info" variant="filled">
              Recent Payments
            </Alert>
            {recentPayments.map((user, index) => (
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem button>
                  <ListItemText
                    primary={user.userName}
                    primaryTypographyProps={{
                      color: 'primary',
                      fontWeight: 'medium',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </List>
            ))}
          </Grid> */}
        </Grid>
      </Container>
      <Container>
        <Grid sx={{ mt: 4, mb: 4 }}>
          <Card variant="outlined">
            <Box sx={{ m: 2 }}>
              <h4> All Social Media</h4>
            </Box>
            <div id="chart">
              <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
            </div>
            <div id="html-dist" />
          </Card>
        </Grid>
      </Container>
      <Container>
        <Grid sx={{ mt: 4, mb: 0 }}>
          <Card variant="outlined">
            <Box sx={{ m: 2 }}>
              <h4> Subscription Churn Chart</h4>
            </Box>
            <div id="chart">
              <ReactApexChart options={options} series={series} type="heatmap" height={350} />
            </div>
            <div id="html-dist" />
          </Card>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={7} xl={7} sx={{ mt: 4, mb: 4 }}>
            <Card variant="outlined">
              <Box sx={{ m: 2 }}>
                <h4>Recent Payment</h4>
              </Box>
              <Box sx={{ height: 400, width: '100%', pb: '10px', pr: '10px', pl: '10px', overflow: 'auto' }}>
                <DataGrid rows={rows} columns={columns} pageSize={5} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={5} xl={5} sx={{ mt: 4, mb: 4, minHeight: '500px' }}>
            <Card variant="outlined" sx={{ minHeight: '460px' }}>
              <Box sx={{ m: 2 }}>
                <h4>Social Media Participation</h4>
              </Box>
              <Box sx={{ pt: 5 }}>
                <div id="chart">
                  <ReactApexChart options={optionss} series={seriess} type="pie" width={380} />
                </div>
                <div id="html-dist" />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
