import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Card, Stack, Container, Typography, TextField, Box, Button, Grid, CircularProgress } from '@mui/material';
import './customStyles.css';
import AppSettings from '../services/AppSettings';

export default function ApplicationSettings() {
  const initialUserService = {
    id: '',
    fullName: '',
    applicationURL: '',
    apiurl: '',
    facebookURL: '',
    instagramURL: '',
    adminURL: '',
    twitterURL: '',
    youtubeURL: '',
    supervisorURL: '',
    companyURL: '',
    userImagesURL: '',
    workerReportImagesURL: '',
    workerDocumentImagesURL: '',
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userGuid, setUserGuid] = useState('');

  const getUser = (id) => {
    setCustomLoader(true);

    AppSettings.getApplicationSettings(id)
      .then((response) => {
        console.log('API response:', response);
        setCurrentUser(response.data);
        setCustomLoader(false);
        // console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    const storedUserGuid = localStorage.getItem('id');

    if (storedUserGuid) {
      setUserGuid(storedUserGuid); // Update state with stored userGuid
    }
  }, []); // This effect will run once on component mount

  // This effect will trigger when userGuid changes
  useEffect(() => {
    if (userGuid) {
      console.log('userid', userGuid);
      getUser(userGuid); // Call getUser only after userGuid is set
    }
  }, [userGuid]); // Dep

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true);
    AppSettings.updateApplicationSettings(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        console.log(response.data);
        setLoading(false);
        toast.success('Success : Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Application Settings | Carib Bargains </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Application Settings
          </Typography>
        </Stack>
        <Card>
          <Box component="form" sx={{ width: '100%', p: 4 }} noValidate autoComplete="off">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Full Name"
                  id="fullName"
                  name="fullName"
                  value={currentUser.fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Application URL"
                  id="applicationURL"
                  name="applicationURL"
                  value={currentUser.applicationURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Api URL"
                  id="apiurl"
                  name="apiurl"
                  value={currentUser.apiurl}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Facebook URL"
                  id="facebookURL"
                  name="facebookURL"
                  value={currentUser.facebookURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Youtube URL"
                  id="youtubeURL"
                  name="youtubeURL"
                  value={currentUser.youtubeURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Instagram URL"
                  id="instagramURL"
                  name="instagramURL"
                  value={currentUser.instagramURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Twitter URL"
                  id="twitterURL"
                  name="twitterURL"
                  value={currentUser.twitterURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="admin URL"
                  id="adminURL"
                  name="adminURL"
                  value={currentUser.adminURL}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Supervisor URL"
                  id="supervisorURL"
                  name="supervisorURL"
                  value={currentUser.supervisorURL}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Company URL"
                  id="companyURL"
                  name="companyURL"
                  value={currentUser.companyURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="   User Image URL"
                  id="   UserimageURL"
                  name="   UserimageURL"
                  value={currentUser.userImagesURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="  Worker Report ImagesURL"
                  id="   workerReportImagesURL"
                  name="   workerReportImagesURL"
                  value={currentUser.workerReportImagesURL}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <TextField
                  fullWidth
                  label="Worker Document ImagesURL"
                  id="   workerDocumentImagesURL"
                  name="   workerDocumentImagesURL"
                  value={currentUser.workerDocumentImagesURL}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div style={{ padding: 2, margin: 15 }}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
